<template>
	<div class="customer-sex">
		<div class="title">搜索商品：</div>
		<div class="tip">在设置时间范围内，客户使用关键词搜索商品的总次数</div>
		
		<div>
			<div class="buy-item">
				<div class="flex">
					<div style="margin-right: 10px;">以下任意一个关键词，在</div>
					<time-mod ref="timeMod1" :value="ruleForm" :disabled="disabled"></time-mod>
					<div style="margin-left: 10px;">期间，搜索次数</div>
				</div>
				<div class="flex">
					<num-mod ref="numMod" :value="ruleForm" :disabled="disabled" txt="次" :max="99" :precision="0"></num-mod>
				</div>
				
				<el-form :model="ruleForm" :rules="rules" class="item" ref="ruleForm" label-width="0px">
					<el-form-item label="" prop="list">
						<div class="add-tag">
							<div v-if="disabled">关键字：</div>
							<el-tag type="info" :closable="disabled?false:true" v-for="(item, index) in ruleForm.list" @close="handleClose(index)" style="margin-right: 10px;">{{item}}</el-tag>
							<el-input v-show="isAdd" :autofocus="true" v-model="tag" maxlength="10" style="width: 200px;" placeholder="输入关键字,回车添加" @change="handleAdd" @blur="handleAdd"></el-input>
							<div v-show="!isAdd && ruleForm.list.length<5 && !disabled"><el-link type="primary" @click="addKey" style="line-height: 18px;">添加关键字</el-link></div>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
		
	</div>
</template>

<script>
	
	// import buyProductItem from './buyProductItem.vue';
	
	import timeMod from '../components/time.vue';
	import numMod from '../components/num.vue';
	import productMod from '../components/product.vue';
	
	export default {
		components: {
			// buyProductItem
			timeMod,
			numMod,
			productMod
		},
		props: {
			type: {
				type: Number,
				default: 0
			},
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			disabled: {
				type:Boolean,
				default:false
			}
		},
		data() {
			
			var validList = (rule, value, callback) => {
				if (this.isAdd){
					callback();
				}else{
					if (!value.length){
						callback(new Error('请添加关键字'));
					}else{
						callback();
					}
				}
				
			};
			
			return {
				ruleForm: {
					list: [],
				},
				rules: {
					list: [{
						required: true,
						trigger: 'change',
						validator: validList
					}]
				},
				
				
				isAdd: false,
				tag: ''
			};
		},
		beforeMount() {
			var obj = JSON.parse(JSON.stringify(this.itemData));
			if (obj.Id){
				this.ruleForm = obj;
				
				if(obj.TagRuleConfigDetailRelationIdList && obj.TagRuleConfigDetailRelationIdList.length){
					this.ruleForm.list = obj.TagRuleConfigDetailRelationIdList.map(item=>{
						return item.RelationValue;
					})
				}else{
					this.ruleForm.list = [];
				}
			}
		},
		methods: {
			validForm() {
				return new Promise((resolve, reject) => {
					
					var p1 = new Promise((resolve, reject) => {
						var dom = this.$refs['numMod'];
						dom.validForm().then(res=>{
							resolve(res)
						}).catch(err=>{
							reject();
						})
					})
					var p2 = new Promise((resolve, reject) => {
						var dom = this.$refs['timeMod1'];
						dom.validForm().then(res=>{
							resolve(res)
						}).catch(err=>{
							reject();
						})
					})
					var p3 = new Promise((resolve, reject) => {
						this.$refs['ruleForm'].validate((valid) => {
							if (valid) {
								resolve();
							} else {
								reject('')
							}
						});
					})
			
					Promise.all([p1, p2, p3]).then(res=>{
						if (res[1]){
							var list = [];
							
							
							list.push({
								TagRuleConfigId: 0,
								Type: this.type,
								StartTime: res[1].time[0],
								EndTime: this.formatNextDate(res[1].time[1]),
								DynamicTimeType: res[1].DynamicTimeType,
								TimeType: res[1].TimeType,
								ConditionType: res[0].value,
								Num: res[0].value === 6 ? res[0].min : res[0].total,
								MaxNum: res[0].max,
								TagRuleConfigDetailRelationIdList: this.ruleForm.list.map(item=>{
									return {
										RelationValue: item
									}
								})
							});
							
							resolve(list);
						}
					}).catch(err=>{
						reject();
						console.log(err, 'catch')
					})
				})
			},
			validate(item){
				return new Promise((resolve, reject) => {
					item.validForm().then(res=>{
						resolve(res)
					}).catch(err=>{
						reject()
					})
				})
			},
			
			addKey(){
				this.isAdd = true;
			},
			handleAdd(){
				
				var tag = this.tag.trim();
				
				if (tag){
					this.ruleForm.list.push(tag);
				}
				
				this.tag = '';
				this.isAdd = false;
			},
			handleClose(index){
				
				this.ruleForm.list.splice(index, 1);
				this.$forceUpdate();
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.customer-sex {
		padding: 15px 20px 15px;

		.title {
			font-size: 14px;
			margin-bottom: 7px;
		}

		.tip {
			font-size: 13px;
			line-height: 18px;
			color: #999;
		}
		.buy-item{
			margin-top: 15px;
			border-bottom: 1px dashed #ddd;
			
			&:last-child{
				border: none;
			}
		}
		
		.add{
			margin: 15px 0;
		}
		
		.flex{
			display: flex;
			line-height: 36px;
			white-space: nowrap;
			flex-wrap: wrap;
		}
		
		.add-tag{
			display: flex;
			align-items: center;
		}
	}
</style>
