<template>
	<div class="customer-sex">
		<div class="title">参与打包一口价活动：</div>
		<div class="tip">在设置时间范围内，客户支付成功的订单中，包含打包一口价活动优惠的订单数量；支付成功即计入，不考虑退款</div>

		<div class="flex flex-box">
			<div class="flex">
				<div style="margin-right: 10px;">在</div>
				<time-mod ref="timeMod1" :value="ruleForm" :disabled="disabled"></time-mod>
				<div style="margin-left: 10px;">期间，</div>
			</div>
			<div class="flex">
				<div class="txt">参与打包一口价活动</div>
				<num-mod ref="numMod" :value="ruleForm" :disabled="disabled" txt="次" :max="99" :precision="0"></num-mod>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		MemberUserlevels
	} from '@/api/TurnTomySelf';
	
	import timeMod from '../components/time.vue';
	import numMod from '../components/num.vue';

	export default {
		components: {
			timeMod,
			numMod
		},
		props: {
			type: {
				type: Number,
				default: 0
			},
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			disabled: {
				type:Boolean,
				default:false
			}
		},
		data() {
			return {
				ruleForm: {
					time: '',
					total: undefined,
					value: 1
				}
			};
		},
		beforeMount() {
			var obj = JSON.parse(JSON.stringify(this.itemData));
			if (obj.Id){
				this.ruleForm = obj;
			}
		},
		methods: {
			validForm() {
				return new Promise((resolve, reject) => {
					
					var p1 = new Promise((resolve, reject) => {
						var dom = this.$refs['numMod'];
						dom.validForm().then(res=>{
							resolve(res)
						}).catch(err=>{
							reject();
						})
					})
					var p2 = new Promise((resolve, reject) => {
						var dom = this.$refs['timeMod1'];
						
						dom.validForm().then(res=>{
							resolve(res)
						}).catch(err=>{
							reject();
						})
					})
			
					Promise.all([p1, p2]).then(res=>{
			
						if (res[1]){
							var list = [];
							list.push({
								TagRuleConfigId: 0,
								Type: this.type,
								StartTime: res[1].time[0],
								EndTime: this.formatNextDate(res[1].time[1]),
								DynamicTimeType: res[1].DynamicTimeType,
								TimeType: res[1].TimeType,
								ConditionType: res[0].value,
								Num: res[0].value === 6 ? res[0].min : res[0].total,
								MaxNum: res[0].max
							});
							
							resolve(list);
						}
					}).catch(err=>{
						reject();
						console.log(err, 'catch')
					})
				})
			},
			handleAddItem(){
				this.ruleForm.list.push({
					time: '',
					total: undefined,
					value: 1,
				})
			},
			handleDelete(index){
				this.ruleForm.list.splice(index, 1)
			}
		}
	}
</script>

<style lang="less" scoped>
	.customer-sex {
		padding: 15px 20px 1px;

		.title {
			font-size: 14px;
			margin-bottom: 7px;
		}

		.tip {
			font-size: 13px;
			color: #999;
			line-height: 18px;
		}
		
		.flex{
			display: flex;
			line-height: 36px;
			white-space: nowrap;
			flex-wrap: wrap;
		}
		.txt{
			height: 36px;
			line-height: 36px;
			white-space: nowrap;
		}
		.flex-box{
			flex-wrap: wrap;
			margin-top: 15px;
		}
		.delete{
			height: 36px;
			line-height: 36px;
			font-size: 20px;
			margin-left: 30px;
			color: #999;
			cursor: pointer;
		}
		
		.add{
			margin: 15px 0;
		}
	}
</style>
