<template>
	<div class="customer-sex">
		<div class="title">成为推手时间：</div>
		<div class="tip">成为推手时间在设置的时间范围内的客户</div>
		
		<div class="flex">
			<div style="margin-right: 10px;">在</div>
			<time-mod ref="timeMod" :disabled="disabled"></time-mod>
			<div style="margin-left: 10px;">成为推手</div>
		</div>

	</div>
</template>

<script>
	
	import timeMod from '../components/time.vue';
	
	export default {
		components: {
			timeMod
		},
		props: {
			type: {
				type: Number,
				default: 0
			},
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			disabled: {
				type:Boolean,
				default:false
			}
		},
		data() {
			return {
				
			};
		},
		mounted(){
			if (this.itemData.Id){
				var obj = JSON.parse(JSON.stringify(this.itemData));
				var mod = this.$refs['timeMod'];
				if (obj.EndTime && obj.StartTime){
					mod.ruleForm.time = [obj.StartTime, obj.EndTime];
				}
				mod.ruleForm.DynamicTimeType = obj.DynamicTimeType || '';
				mod.ruleForm.TimeType = obj.TimeType || 0;
			}
		},
		methods: {
			validForm() {
				return new Promise((resolve, reject) => {
					this.$refs['timeMod'].validForm().then(res => {
						var obj = {
							TagRuleConfigId: 0,
							Type: this.type,
							StartTime: res.time[0],
							EndTime: this.formatNextDate(res.time[1]),
							DynamicTimeType: res.DynamicTimeType,
							TimeType: res.TimeType
						}
						resolve(obj);
					}).catch(err=>{
						reject()
					});
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.customer-sex {
		padding: 15px 20px 1px;

		.title {
			font-size: 14px;
			margin-bottom: 7px;
		}

		.tip {
			font-size: 13px;
			color: #999;
			margin-bottom: 15px;
		}
		
		.flex{
			display: flex;
			line-height: 36px;
			white-space: nowrap;
			flex-wrap: wrap;
		}
	}
</style>
