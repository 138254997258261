<template>
	<div class="customer-sex">
		<div class="title">会员级别：</div>
		<div class="tip">客户当前会员级别</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" size="mini">
			<el-form-item label="" prop="check" inline-message>
				<el-checkbox-group v-model="ruleForm.check" :disabled="disabled">
					<el-checkbox v-for="item in levelist" :label="item.Value" :value="item.Value" :key="item.Value">{{item.Text}}
					</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
		</el-form>

	</div>
</template>

<script>
	
	import {
		MemberUserlevels
	} from '@/api/TurnTomySelf'
	
	export default {
		components: {},
		props: {
			type: {
				type: Number,
				default: 0
			},
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			disabled: {
				type:Boolean,
				default:false
			}
		},
		data() {

			var validCheck = (rule, value, callback) => {
				if (!value.length) {
					callback(new Error('请选择会员级别'));
				} else {
					callback();
				}
			};

			return {
				ruleForm: {
					check: []
				},
				rules: {
					check: [{
						required: true,
						trigger: 'change',
						validator: validCheck
					}]
				},

				levelist: []
			};
		},
		beforeMount() {
			var obj = JSON.parse(JSON.stringify(this.itemData));
			if (obj.TagRuleConfigDetailRelationIdList && obj.TagRuleConfigDetailRelationIdList.length){
				this.ruleForm.check = obj.TagRuleConfigDetailRelationIdList.map(item=>{
					return item.RelationId
				})
			}
			this.getUserlevels()
		},
		methods: {
			validForm(){
				return new Promise((resolve, reject)=>{
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							var obj = {
								TagRuleConfigId: 0,
								Type: this.type,
								TagRuleConfigDetailRelationIdList: this.ruleForm.check.map(item=>{
									return {
										RelationId: item
									}
								})
							}
							resolve(obj);
						} else {
							reject()
						}
					});
				})
				
			},
			//获取会员等级
			async getUserlevels() {
				try {
					let result = await MemberUserlevels()
					this.levelist = result.Result.map(item=>{
						item.Value = +item.Value;
						return item;
					})
				} catch (err) {}
			},
		}
	}
</script>

<style lang="less" scoped>
	.customer-sex {
		padding: 15px 20px 1px;

		.title {
			font-size: 14px;
			margin-bottom: 7px;
		}

		.tip {
			font-size: 13px;
			color: #999;
			margin-bottom: 5px;
		}
	}
</style>
