<template>
	<div class="newOrOld-customer">
		<div class="title">新老客户：</div>
		<div class="tip">
			新客户：从商城创建至今，<span style="color: #F56C6C;">从未</span>在商城内支付成功过订单的客户<br/>
			老客户：从商城创建至今，<span style="color: #F56C6C;">至少</span>在商城内支付成功过<span style="color: #F56C6C;">一次</span>订单的客户
		</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" size="mini">
			<el-form-item label="" prop="check" inline-message>
				<el-radio-group v-model="ruleForm.check" :disabled="disabled">
					<el-radio v-for="item in checkList" :label="item.id" :key="item.id">{{item.value}}
					</el-radio>
				</el-radio-group>
			</el-form-item>
		</el-form>

	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			type: {
				type: Number,
				default: 0
			},
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			disabled: {
				type:Boolean,
				default:false
			}
		},
		data() {

			var validCheck = (rule, value, callback) => {
				if (value === null) {
					callback(new Error('请选择新老客户类别'));
				} else {
					callback();
				}
			};

			return {
				ruleForm: {
					check: null
				},
				rules: {
					check: [{
						required: true,
						trigger: 'change',
						validator: validCheck
					}]
				},

				checkList: [{
					id: 0,
					value: '新客户'
				}, {
					id: 1,
					value: '老客户'
				}]
			};
		},
		beforeMount() {
			var obj = JSON.parse(JSON.stringify(this.itemData));
			if (obj.TagRuleConfigDetailRelationIdList && obj.TagRuleConfigDetailRelationIdList.length){
				this.ruleForm.check = obj.TagRuleConfigDetailRelationIdList.map(item=>{
					return item.RelationId
				})[0]
			}
		},
		methods: {
			validForm(){
				return new Promise((resolve, reject)=>{
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							var obj = {
								TagRuleConfigId: 0,
								Type: this.type,
								TagRuleConfigDetailRelationIdList: [{RelationId: this.ruleForm.check}]
							}
							resolve(obj);
						} else {
							reject()
						}
					});
				})
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.newOrOld-customer {
		padding: 15px 20px 1px;

		.title {
			font-size: 14px;
			margin-bottom: 7px;
		}

		.tip {
			font-size: 13px;
			color: #999;
			margin-bottom: 5px;
			line-height: 18px;
		}
	}
</style>
