<template>
	<el-dialog title="选择页面" :visible="visible" width="700px" :before-close="handleClose">
		<div class="dialog-content">
			<el-tabs v-model="activeName" type="card">
				<el-tab-pane label="系统页面" name="first">
					<el-table ref="multipleTable" :data="systemPage" tooltip-effect="dark" style="width: 100%">
						<el-table-column prop="" width="30">
							<template slot-scope="scope">
								<el-radio v-model="scope.row.checked" :label="true" @change="systemPageChange(scope.row)"></el-radio>
							</template>
						</el-table-column>
						<el-table-column prop="name" label="页面名称">
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="商品分组" name="second">
					<el-table ref="multipleTable" :data="groupList" tooltip-effect="dark" style="width: 100%">
						<el-table-column prop="" width="30">
							<template slot-scope="scope">
								<el-radio v-model="scope.row.checked" :label="true" @change="groupPageChange(scope.row)"></el-radio>
							</template>
						</el-table-column>
						<el-table-column prop="GroupName" label="分组名称">
						</el-table-column>
					</el-table>
					<div style="margin-top: 20px;">
						<el-pagination
						      @size-change="handleSizeChange"
						      @current-change="handleCurrentChange"
						      :current-page="groupPageIndex"
						      :page-sizes="[10, 20, 30, 40, 50]"
						      :page-size="groupPageSize"
						      layout="total, sizes, prev, pager, next, jumper"
						      :total="groupTotal">
						    </el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="自定义页面" name="third">
					<el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%">
						<el-table-column prop="" width="30">
							<template slot-scope="scope">
								<el-radio v-model="scope.row.checked" :label="true" @change="pageChange(scope.row)"></el-radio>
							</template>
						</el-table-column>
						<el-table-column prop="PageName" label="页面名称">
						</el-table-column>
					</el-table>
					<div style="margin-top: 20px;">
						<el-pagination
						      @size-change="sizeChange"
						      @current-change="currentChange"
						      :current-page="pageIndex"
						      :page-sizes="[10, 20, 30, 40, 50]"
						      :page-size="pageSize"
						      layout="total, sizes, prev, pager, next, jumper"
						      :total="total">
						    </el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		

		<span slot="footer" class="dialog-footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="handleSure">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	
	import {productGroupListData} from '@/api/goods.js';
	import api from '@/api/other.js';
	
	export default {
		components: {},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				activeName: 'first',
				systemPage: [{
					id: 1,
					name: '商城首页',
					path: 'pages/index/index',
					checked: false
				}, {
					id: 2,
					name: '全部商品',
					path: 'pages/allProduct/allProduct',
					checked: false
				}],
				
				selectionList: [],
				
				groupPageIndex: 1,
				groupPageSize: 10,
				groupTotal: 0,
				groupList: [],
				
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				list: []
			};
		},
		beforeMount() {
			this.getGroupList();
			this.getList();
		},
		methods: {
			async getGroupList(){
				try{
					const res = await productGroupListData({
						Skip: (this.groupPageIndex-1)*this.groupPageSize,
						Take: this.groupPageSize
					})
					res.Result.Results.map(item=>{
						item.checked = false;
						return item;
					})
					this.groupList = res.Result.Results;
					this.groupTotal = res.Result.Total;
				}catch(e){}
			},
			async getList(){
				try{
					const res = await api.mallShopDecorateBoxList({
						Skip: (this.pageIndex-1)*this.pageSize,
						Take: this.pageSize
					})
					res.Result.Results.map(item=>{
						item.checked = false;
						return item;
					})
					this.list = res.Result.Results;
					this.total = res.Result.Total;
					
					// console.log(this.list,'list')
				}catch(e){}
			},
			handleClose() {
				this.$emit('close')
			},
			systemPageChange(record){
				this.systemPage.map(item=>{
					if (item.id !== record.id){
						item.checked = false;
					}
					return item;
				})
			},
			groupPageChange(record){
				this.groupList.map(item=>{
					if (item.Id !== record.Id){
						item.checked = false;
					}
					return item;
				})
			},
			handleSizeChange(val){
				this.groupPageSize = val;
				this.getGroupList();
			},
			handleCurrentChange(val){
				this.groupPageIndex = val;
				this.getGroupList();
			},
			pageChange(record){
				this.list.map(item=>{
					if (item.MallShopDecorateId !== record.MallShopDecorateId){
						item.checked = false;
					}
					return item;
				})
			},
			sizeChange(val){
				this.pageSize = val;
				this.getList();
			},
			currentChange(val){
				this.pageIndex = val;
				this.getList();
			},
			
			handleSure(){
				if (this.activeName === 'first'){
					// console.log(this.systemPage)
					var page = this.systemPage.filter(item=>{
						return item.checked
					})
					if (page.length){
						this.$emit('change', {
							PageRangeType: 1,
							PagePath: page[0].path,
							RelationId: '',
							pageName: page[0].name
						})
						this.handleClose()
					}else{
						this.$message.error('请选择页面');
					}
				}
				if (this.activeName === 'second'){
					var page = this.groupList.filter(item=>{
						return item.checked
					})
					if (page.length){
						this.$emit('change', {
							PageRangeType: 1,
							PagePath: 'pages/productGroup/productGroup',
							RelationId : page[0].Id,
							pageName: page[0].GroupName
						})
						this.handleClose()
					}else{
						this.$message.error('请选择页面');
					}
				}
				if (this.activeName === 'third'){
					var page = this.list.filter(item=>{
						return item.checked
					})
					if (page.length){
						this.$emit('change', {
							PageRangeType: 1,
							PagePath: 'pages/cusPage/cusPage?id='+page[0].MallShopDecorateId,
							RelationId : '',
							pageName: page[0].PageName
						})
						this.handleClose()
					}else{
						this.$message.error('请选择页面');
					}
				}
			},
		}
	}
</script>

<style lang="less" scoped>
.dialog-content{
	max-height: 55vh;
	overflow: auto;
}
</style>
