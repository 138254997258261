<template>
	<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px">
		<div class="item">
			<el-form-item label="">
				<el-select v-model="ruleForm.TimeType" :disabled="disabled" style="width: 140px;margin-right: 5px;" @change="selectChange">
					<el-option v-for="item in typeList" :key="item.id" :label="item.label" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="" prop="time" v-if="ruleForm.TimeType===0">
				<el-date-picker 
					:disabled="disabled"
					v-model="ruleForm.time" 
					type="daterange" 
					value-format="yyyy-MM-dd" 
					range-separator="至" 
					start-placeholder="开始日期" 
					end-placeholder="结束日期"
					style="width: 250px;"
					>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="" prop="DynamicTimeType" v-if="ruleForm.TimeType===1">
				<el-select v-model="ruleForm.DynamicTimeType" :disabled="disabled" style="width: 180px;">
					<el-option v-for="item in timeList" :key="item.id" :value="item.id" :label="item.fullText">
						{{item.label}}<span style="color: #999;font-size: 12px;" v-if="item.tips">({{item.tips}})</span>
					</el-option>
				</el-select>
			</el-form-item>
			
		</div>
	</el-form>
</template>

<script>
	
	export default {
		components: {},
		props: {
			type: {
				type: Number,
				default: 0
			},
			disabled: {
				type:Boolean,
				default:false
			},
			value: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
		},
		data() {

			var validCheck = (rule, value, callback) => {
				if (!value) {
					callback(new Error('请选择日期'));
				} else {
					callback();
				}
			};
			var validCheck1 = (rule, value, callback) => {
				if (!value) {
					callback(new Error('请选择时间'));
				} else {
					callback();
				}
			};

			return { 
				ruleForm: {
					time: '',
					DynamicTimeType: 1,
					TimeType: 0
				},
				rules: {
					time: [{
						required: true,
						trigger: 'change',
						validator: validCheck
					}],
					DynamicTimeType: [{
						required: true,
						trigger: 'change',
						validator: validCheck1
					}]
				},
				
				typeList: [{
					id: 0,
					label: '固定时间段'
				},{
					id: 1,
					label: '动态时间段'
				}],
				
				timeList: [
					{
						id: 1,
						label: '当天',
						fullText: '当天 （自然日）',
						tips: '自然日'
					},
					{
						id: 2,
						label: '上一天',
						fullText: '上一天 （自然日）',
						tips: '自然日'
					},
					{
						id: 3,
						label: '当前周',
						fullText: '当前周 （自然周）',
						tips: '自然周'
					},
					{
						id: 4,
						label: '上一周',
						fullText: '上一周 （自然周）',
						tips: '自然周'
					},
					{
						id: 5,
						label: '当前月',
						fullText: '当前月 （自然月）',
						tips: '自然月'
					},
					{
						id: 6,
						label: '上个月',
						fullText: '上个月 （自然月）',
						tips: '自然月'
					},
					{
						id: 7,
						label: '近3天',
						tips: '',
						fullText: '近3天'
					},
					{
						id: 8,
						label: '近7天',
						fullText: '近7天',
						tips: ''
					},
					{
						id: 9,
						label: '近15天',
						fullText: '近15天',
						tips: ''
					},
					{
						id: 10,
						label: '近30天',
						fullText: '近30天',
						tips: ''
					},
					{
						id: 11,
						label: '近90天',
						fullText: '近90天',
						tips: ''
					},
					{
						id: 12,
						label: '商城上线至今',
						fullText: '商城上线至今',
						tips: ''
					},
				],
				
				
				
				pickerOptions: {
					disabledDate(time) {
						var year = new Date().getFullYear();
						var month = new Date().getMonth();
						var day = new Date().getDate();
						return time.getTime() >= new Date(year, month, day).getTime();
					},
				}
			};
		},
		watch: {
			value() {
				for (let item in this.ruleForm){
					if (this.value[item]){
						this.ruleForm[item] = this.value[item]
					}
				}
			}
		},
		mounted() {
			
			for (let item in this.ruleForm){
				if (this.value[item]){
					this.ruleForm[item] = this.value[item]
				}
			}
			
		},
		methods: {
			validForm() {
				return new Promise((resolve, reject) => {
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							resolve(this.ruleForm);
						} else {
							reject('')
						}
					});
				})
			},
			selectChange(){
				this.$refs['ruleForm'].clearValidate();
			}
		}
	}
</script>

<style lang="less" scoped>
	.item{
		display: flex;
		flex-wrap: wrap;
	}
</style>
