<template>
	<div class="customer-sex">
		<div class="title">浏览商城页面：</div>
		<div class="tip">在设置时间范围内，客户浏览商城页面的次数；同一个客户同一个页面浏览多次计多次；可设置指定页面浏览次数或者所有页面总浏览次数</div>

		<div class="flex flex-box">
			<div class="flex">
				<div style="margin-right: 10px;">在</div>
				<time-mod ref="timeMod1" :value="ruleForm" :disabled="disabled"></time-mod>
				<div style="margin-left: 10px;">期间，</div>
			</div>
			<div class="flex">
				<el-form :model="ruleForm" :rules="rules" class="item" ref="ruleForm" label-width="0px">
					<el-form-item label="" prop="pageName">
						<el-popover
						    placement="bottom"
						    v-model="popoverVisible"
						    width="100"
						    trigger="click">
							<div class="popover-wraper">
								<div :class="{popoverCurrent: ruleForm.PageRangeType === 0}" @click="rangeTypeChange(0)">商城所有页面</div>
								<div :class="{popoverCurrent: ruleForm.PageRangeType === 1}"  @click="rangeTypeChange(1)">指定商城页面</div>
							</div>
							<div slot="reference">
								<div class="page" v-if="!disabled">
									<span v-if="!ruleForm.pageName">商城所有页面</span>
									<span v-else>{{ruleForm.pageName}}</span>
									<i class="el-icon-arrow-down"></i>
								</div>
								<div class="page" v-else style="background-color: #F5F7FA;color: #C0C4CC;">
									<span v-if="!ruleForm.pageName">商城所有页面</span>
									<span v-else>{{ruleForm.pageName}}</span>
									<i class="el-icon-arrow-down"></i>
								</div>
							</div>
							
						  </el-popover>
						
					</el-form-item>
				</el-form>
				<div class="txt">（总）浏览次数</div>
			</div>
			<div class="flex">
				<num-mod ref="numMod" :value="ruleForm" :disabled="disabled" txt="次" :max="99" :precision="0"></num-mod>
			</div>
		</div>
		
		<select-page :visible="dialogVisible" @close="dialogVisible=false" @change="dataChange"></select-page>
	</div>
</template>

<script>
	import {
		MemberUserlevels
	} from '@/api/TurnTomySelf';
	
	import selectPage from '../components/selectPage.vue';
	import timeMod from '../components/time.vue';
	import numMod from '../components/num.vue';

	export default {
		components: {
			timeMod,
			numMod,
			selectPage
		},
		props: {
			type: {
				type: Number,
				default: 0
			},
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			disabled: {
				type:Boolean,
				default:false
			}
		},
		data() {
			

			
			return {
				ruleForm: {
					time: '',
					total: undefined,
					value: 1,
					PageRangeType: 0,
					PagePath: '',
					RelationId : '',
					pageName: ''
				},
				rules: {
				},
				
				dialogVisible: false,
				popoverVisible: false
			};
		},
		beforeMount() {
			var obj = JSON.parse(JSON.stringify(this.itemData));
			if (obj.Id){
				this.ruleForm = obj;
				if (obj.PageRangeType === 1){
					this.ruleForm.RelationId = obj.TagRuleConfigDetailRelationIdList[0].RelationId;
					this.ruleForm.PagePath = obj.TagRuleConfigDetailRelationIdList[0].PagePath;
					this.ruleForm.pageName = obj.TagRuleConfigDetailRelationIdList[0].PageName;
				}
			}
		},
		methods: {
			validForm() {
				return new Promise((resolve, reject) => {
					
					var p1 = new Promise((resolve, reject) => {
						var dom = this.$refs['numMod'];
						
						dom.validForm().then(res=>{
							resolve(res)
						}).catch(err=>{
							reject();
						})
					})
					var p2 = new Promise((resolve, reject) => {
						var dom = this.$refs['timeMod1'];
						
						dom.validForm().then(res=>{
							resolve(res)
						}).catch(err=>{
							reject();
						})
					})

					Promise.all([p1, p2]).then(res=>{

						if (res[1]){
							var list = [];
							list.push({
								TagRuleConfigId: 0,
								Type: this.type,
								StartTime: res[1].time[0],
								EndTime: this.formatNextDate(res[1].time[1]),
								DynamicTimeType: res[1].DynamicTimeType,
								TimeType: res[1].TimeType,
								ConditionType: res[0].value,
								Num: res[0].value === 6 ? res[0].min : res[0].total,
								MaxNum: res[0].max,
								PageRangeType: this.ruleForm.PageRangeType,
								TagRuleConfigDetailRelationIdList: [{
									RelationId: this.ruleForm.RelationId,
									PagePath: this.ruleForm.PagePath,
									PageName: this.ruleForm.pageName
								}]
							});
							
							resolve(list);
						}
					}).catch(err=>{
						reject();
						console.log(err, 'catch')
					})
				})
			},
			rangeTypeChange(index){
				if (index === 0){
					this.ruleForm.PagePath = '';
					this.ruleForm.RelationId = '';
					this.ruleForm.pageName = '';
					this.ruleForm.PageRangeType = index;
				}else{
					this.handleShowPop();
				}
				this.popoverVisible = false;
			},
			handleShowPop(){
				this.dialogVisible = true;
			},
			dataChange(record){
				console.log(record)
				for (let item in record){
					this.ruleForm[item] = record[item];
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	
	.popover-wraper{
		div{
			height: 36px;
			line-height: 36px;
			cursor: pointer;
		}
		.popoverCurrent{
			color: #409EFF;
		}
	}
	
	.customer-sex {
		padding: 15px 20px 1px;

		.title {
			font-size: 14px;
			margin-bottom: 7px;
		}

		.tip {
			font-size: 13px;
			color: #999;
			line-height: 18px;
		}
		
		.flex{
			display: flex;
			line-height: 36px;
			white-space: nowrap;
			flex-wrap: wrap;
		}
		.txt{
			height: 36px;
			line-height: 36px;
			white-space: nowrap;
		}
		.flex-box{
			flex-wrap: wrap;
			border-bottom: 1px dashed #ddd;
			margin-top: 15px;
		}
		.delete{
			height: 36px;
			line-height: 36px;
			font-size: 20px;
			margin-left: 30px;
			color: #999;
			cursor: pointer;
		}
		
		.add{
			margin: 15px 0;
		}
	}
	
	.page{
		width: 180px;
		height: 36px;
		padding: 0 10px;
		border: 1px solid #ddd;
		display: flex;
		align-items: center;
		border-radius: 4px;
		overflow: hidden;
		
		span{
			flex: 1 1 auto;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-right: 10px;
		}
		i{
			flex: 0 0 auto;
		}
	}
</style>
