<template>
	<div class="add-tag">
		<div class="menu">
			<div class="title">{{menuTitle}}</div>

			<div class="menu-box">
				<div style="margin: 15px 15px 10px;">
					<el-input v-model="keywords" placeholder="试试输入下单、支付、商品等关键字，按下回车搜索" style="width: 100%;" @change="handleSearch"></el-input>
				</div>
				
				<div class="tag-list" v-if="!isSearch">
					<div class="tag" :class="{tagCurrent: tagIndex === index}" v-for="(item, index) in menuList" :key="index" @click="menuScroll(index)">
						{{item.title}}
					</div>
				</div>
				
				<div class="menu-item-box" v-for="(item, index) in menuList" :key="index" v-if="!isSearch">
					<div class="menu-title">{{item.title}}</div>
					<div class="menu-item-list">
						<div class="menu-item" :class="{menuItemDisabled:disabled,menuItemCheck:record.checked}"
							v-for="record in item.list" :key="record.id" @click="addTag(record)">
							{{record.value}}
						</div>
					</div>
				</div>
				<div class="menu-item-box" v-if="isSearch">
					<div class="menu-title"></div>
					<div class="menu-item-list" v-if="searchMenuList.length">
						<div class="menu-item" :class="{menuItemDisabled:disabled,menuItemCheck:record.checked}"
							v-for="record in searchMenuList" :key="record.id" @click="addTag(record)">
							{{record.value}}
						</div>
					</div>
					<div v-else style="margin-left: 20px;color: #666;">未匹配到相应条件标签</div>
				</div>

			</div>
		</div>

		<div class="content">
			<div class="title">{{contentTitle}}</div>
			<div class="list auto-add-tag-edit">
				<div class="item" :class="['item-'+index]" v-for="(item, index) in list" :key="item.id">
					<!-- 客户性别 -->
					<sex ref="item" :itemData="item" v-if="item.id === 1" :type="item.id" :disabled="disabled"></sex>

					<!-- 会员级别 -->
					<vipLevel ref="item" :itemData="item" v-if="item.id === 2" :type="item.id" :disabled="disabled">
					</vipLevel>

					<!-- 推手级别 -->
					<agentLevel ref="item" :itemData="item" v-if="item.id === 3" :type="item.id" :disabled="disabled">
					</agentLevel>

					<!-- 成为客户时间 -->
					<becameCustomerTime ref="item" :itemData="item" v-if="item.id === 4" :type="item.id"
						:disabled="disabled"></becameCustomerTime>

					<!-- 成为会员时间 -->
					<becameVipTime ref="item" :itemData="item" v-if="item.id === 5" :type="item.id"
						:disabled="disabled"></becameVipTime>

					<!-- 成为推手时间 -->
					<becameAgentTime ref="item" :itemData="item" v-if="item.id === 6" :type="item.id"
						:disabled="disabled"></becameAgentTime>


					<!-- 笔单价 -->
					<anOrderPrice ref="item" :itemData="item" v-if="item.id === 7" :type="item.id" :disabled="disabled">
					</anOrderPrice>

					<!-- 累计下单笔数 -->
					<orderTotal ref="item" :itemData="item" v-if="item.id === 8" :type="item.id" :disabled="disabled">
					</orderTotal>

					<!-- 累计下单金额 -->
					<priceTotal ref="item" :itemData="item" v-if="item.id === 9" :type="item.id" :disabled="disabled">
					</priceTotal>

					<!-- 累计支付笔数 -->
					<payTotal ref="item" :itemData="item" v-if="item.id === 10" :type="item.id" :disabled="disabled">
					</payTotal>

					<!-- 累计支付金额 -->
					<payPriceTotal ref="item" :itemData="item" v-if="item.id === 11" :type="item.id"
						:disabled="disabled"></payPriceTotal>

					<!-- 累计充值余额 -->
					<rechargeBalanceTotal ref="item" :itemData="item" v-if="item.id === 12" :type="item.id"
						:disabled="disabled"></rechargeBalanceTotal>

					<!-- 累计使用余额 -->
					<useBalanceTotal ref="item" :itemData="item" v-if="item.id === 13" :type="item.id"
						:disabled="disabled"></useBalanceTotal>

					<!-- 累计充值次数 -->
					<rechargeCount ref="item" :itemData="item" v-if="item.id === 14" :type="item.id"
						:disabled="disabled"></rechargeCount>

					<!-- 购买商品 -->
					<buyProduct ref="item" :itemData="item" v-if="item.id === 15" :type="item.id" :disabled="disabled">
					</buyProduct>

					<!-- 新老客户 -->
					<newOrOldCustomer ref="item" :itemData="item" v-if="item.id === 100" :type="item.id"
						:disabled="disabled"></newOrOldCustomer>

					<!-- 成长值 -->
					<growthValue ref="item" :itemData="item" v-if="item.id === 101" :type="item.id"
						:disabled="disabled"></growthValue>

					<!-- 未使用余额 -->
					<notUsedBalance ref="item" :itemData="item" v-if="item.id === 200" :type="item.id"
						:disabled="disabled"></notUsedBalance>

					<!-- 加购物车商品 -->
					<addCartProduct ref="item" :itemData="item" v-if="item.id === 201" :type="item.id"
						:disabled="disabled"></addCartProduct>

					<!-- 最近一次下单 -->
					<lastOrder ref="item" :itemData="item" v-if="item.id === 202" :type="item.id" :disabled="disabled">
					</lastOrder>

					<!-- 最近一次支付 -->
					<lastPay ref="item" :itemData="item" v-if="item.id === 203" :type="item.id" :disabled="disabled">
					</lastPay>
					
					<!-- 浏览商城页面 -->
					<mall-page ref="item" :itemData="item" v-if="item.id === 300" :type="item.id" :disabled="disabled">
					</mall-page>
					
					<!-- 浏览商品详情页 -->
					<page-detail ref="item" :itemData="item" v-if="item.id === 301" :type="item.id" :disabled="disabled">
					</page-detail>
					
					<!-- 分享商城页面 -->
					<share-mall ref="item" :itemData="item" v-if="item.id === 302" :type="item.id" :disabled="disabled">
					</share-mall>
					
					<!-- 分享商品 -->
					<share-detail ref="item" :itemData="item" v-if="item.id === 303" :type="item.id" :disabled="disabled">
					</share-detail>
					
					<!-- 搜索商品 -->
					<search-product ref="item" :itemData="item" v-if="item.id === 304" :type="item.id" :disabled="disabled">
					</search-product>
					
					<!-- 领取优惠券 -->
					<receive-coupon ref="item" :itemData="item" v-if="item.id === 400" :type="item.id" :disabled="disabled">
					</receive-coupon>
					
					<!-- 使用优惠券 -->
					<use-coupon ref="item" :itemData="item" v-if="item.id === 401" :type="item.id" :disabled="disabled">
					</use-coupon>
					
					<!-- 参与拼团活动 -->
					<participatein-group ref="item" :itemData="item" v-if="item.id === 402" :type="item.id" :disabled="disabled">
					</participatein-group>
					
					<!-- 参与助力活动 -->
					<participatein-help ref="item" :itemData="item" v-if="item.id === 403" :type="item.id" :disabled="disabled">
					</participatein-help>
					
					<!-- 参与满减送活动 -->
					<participatein-fullactivity ref="item" :itemData="item" v-if="item.id === 404" :type="item.id" :disabled="disabled">
					</participatein-fullactivity>
					
					<!-- 参与打包一口价活动 -->
					<participateinOnePriceActivity ref="item" :itemData="item" v-if="item.id === 405" :type="item.id" :disabled="disabled">
					</participateinOnePriceActivity>
					
					<!-- 参与限时折扣活动 -->
					<participateinDiscountActivity ref="item" :itemData="item" v-if="item.id === 406" :type="item.id" :disabled="disabled">
					</participateinDiscountActivity>
					
					<!-- 累计推手收益 -->
					<reward ref="item" :itemData="item" v-if="item.id === 500" :type="item.id" :disabled="disabled">
					</reward>

					<div class="delete" v-if="!disabled" @click="handleDelete(index, item.id)"><i
							class="el-icon-close"></i>
					</div>
				</div>
			</div>
		</div>

		

	</div>
</template>

<script>
	import Vue from 'vue';
	
	
	
	//基础属性
	import sex from './baseAttr/sex.vue';
	import vipLevel from './baseAttr/vipLevel.vue';
	import agentLevel from './baseAttr/agentLevel.vue';
	import becameCustomerTime from './baseAttr/becameCustomerTime.vue';
	import becameVipTime from './baseAttr/becameVipTime.vue';
	import becameAgentTime from './baseAttr/becameAgentTime.vue';
	import newOrOldCustomer from './baseAttr/newOrOldCustomer.vue';
	import growthValue from './baseAttr/growthValue.vue';
	
	//交易行为
	import anOrderPrice from './transactionBehavior/anOrderPrice.vue';
	import orderTotal from './transactionBehavior/orderTotal.vue';
	import priceTotal from './transactionBehavior/priceTotal.vue';
	import payTotal from './transactionBehavior/payTotal.vue';
	import payPriceTotal from './transactionBehavior/payPriceTotal.vue';
	import rechargeBalanceTotal from './transactionBehavior/rechargeBalanceTotal.vue';
	import useBalanceTotal from './transactionBehavior/useBalanceTotal.vue';
	import rechargeCount from './transactionBehavior/rechargeCount.vue';
	import buyProduct from './transactionBehavior/buyProduct.vue';
	import notUsedBalance from './transactionBehavior/notUsedBalance.vue';
	import addCartProduct from './transactionBehavior/addCartProduct.vue';
	import lastOrder from './transactionBehavior/lastOrder.vue';
	import lastPay from './transactionBehavior/lastPay.vue';
	
	//浏览和分享
	import mallPage from './browseAndShare/mallPage.vue';
	import pageDetail from './browseAndShare/pageDetail.vue';
	import shareMall from './browseAndShare/shareMall.vue';
	import shareDetail from './browseAndShare/shareDetail.vue';
	import searchProduct from './browseAndShare/searchProduct.vue';
	
	//营销
	import receiveCoupon from './marketing/receiveCoupon.vue';
	import useCoupon from './marketing/useCoupon.vue';
	import participateinGroup from './marketing/participateinGroup.vue';
	import participateinHelp from './marketing/participateinHelp.vue';
	import participateinFullactivity from './marketing/participateinFullactivity.vue';
	import participateinOnePriceActivity from './marketing/participateinOnePriceActivity.vue';
	import participateinDiscountActivity from './marketing/participateinDiscountActivity.vue';
	
	//更多
	import reward from './more/reward.vue';

	import {
		productGroupList,
		productBrandList
	} from '@/api/goods.js';


	export default {
		components: {
			sex,
			vipLevel,
			agentLevel,
			becameCustomerTime,
			becameVipTime,
			becameAgentTime,
			anOrderPrice,
			orderTotal,
			priceTotal,
			payTotal,
			payPriceTotal,
			rechargeBalanceTotal,
			useBalanceTotal,
			rechargeCount,
			buyProduct,
			newOrOldCustomer,
			growthValue,
			notUsedBalance,
			addCartProduct,
			lastOrder,
			lastPay,
			mallPage,
			pageDetail,
			shareMall,
			shareDetail,
			searchProduct,
			receiveCoupon,
			useCoupon,
			participateinGroup,
			participateinHelp,
			participateinFullactivity,
			participateinOnePriceActivity,
			participateinDiscountActivity,
			reward
		},
		props: {
			//是否不可编辑
			disabled: {
				type: Boolean,
				default: false
			},
			menuTitle: {
				type: String,
				default: '点击下面条件标签添加到右侧面板中'
			},
			contentTitle: {
				type: String,
				default: '设置已选中的条件标签（同时满足以下条件的客户即为本次营销推送客户）'
			},
			defaultList: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data() {
			return {
				
				dialogVisible: false,

				menuList: [{
						title: '基础属性',
						list: [{
							id: 1,
							value: '客户性别',
							checked: false
						}, {
							id: 2,
							value: '会员级别',
							checked: false
						}, {
							id: 3,
							value: '推手级别',
							checked: false
						}, {
							id: 4,
							value: '成为客户时间',
							checked: false
						}, {
							id: 6,
							value: '成为推手时间',
							checked: false
						}, {
							id: 5,
							value: '成为会员时间',
							checked: false
						}, {
							id: 100,
							value: '新老客户',
							checked: false
						}, {
							id: 101,
							value: '成长值',
							checked: false
						}],
					},
					{
						title: '交易行为',
						list: [{
							id: 7,
							value: '笔单价',
							checked: false
						}, {
							id: 8,
							value: '累计下单笔数',
							checked: false
						}, {
							id: 9,
							value: '累计下单金额',
							checked: false
						}, {
							id: 10,
							value: '累计支付笔数',
							checked: false
						}, {
							id: 11,
							value: '累计支付金额',
							checked: false
						}, {
							id: 12,
							value: '累计充值余额',
							checked: false
						}, {
							id: 13,
							value: '累计使用余额',
							checked: false
						}, {
							id: 14,
							value: '累计充值次数',
							checked: false
						}, {
							id: 200,
							value: '未使用余额',
							checked: false
						}, {
							id: 15,
							value: '购买商品',
							checked: false
						}, {
							id: 201,
							value: '加购物车商品',
							checked: false
						}, {
							id: 202,
							value: '最近一次下单时间',
							checked: false
						}, {
							id: 203,
							value: '最近一次支付时间',
							checked: false
						}]
					},
					{
						title: '浏览和分享',
						list: [{
								id: 300,
								value: '浏览商城页面',
								checked: false
							},
							{
								id: 301,
								value: '浏览商品详情页',
								checked: false
							},
							{
								id: 302,
								value: '分享商城页面',
								checked: false
							},
							{
								id: 303,
								value: '分享商品',
								checked: false
							},
							{
								id: 304,
								value: '搜索商品',
								checked: false
							},
						]
					}, {
						title: '参与营销活动',
						list: [{
								id: 400,
								value: '领取优惠券',
								checked: false
							},
							{
								id: 401,
								value: '使用优惠券',
								checked: false
							},
							{
								id: 402,
								value: '参与拼团活动',
								checked: false
							},
							{
								id: 403,
								value: '参与助力活动',
								checked: false
							},
							{
								id: 404,
								value: '参与满减送活动',
								checked: false
							},
							{
								id: 405,
								value: '参与打包一口价活动',
								checked: false
							},
							{
								id: 406,
								value: '参与限时折扣活动',
								checked: false
							}
						]
					},
					{
						title: '资产数据',
						list: [{
							id: 500,
							value: '累计推手收益',
							checked: false
						}]
					}
				],
				searchMenuList: [],
				keywords: '',
				isSearch: false,

				groupList: [],
				brandList: [],
				list: [],
				
				tagIndex: 0
			};
		},
		watch: {
			defaultList() {
				this.init();
				
			}
		},
		beforeMount() {
			// console.log(this.defaultList[0].TagRuleConfigDetailRelationIdList.map((v)=>{return v}))
			this.getGroupList();
			this.getBrandList();
			
			if (this.defaultList.length){
				this.init()
			}
			
			// setTimeout(()=>{
			// 	this.getGroupList();
			// 	this.getBrandList();
			// }, 500)
		},
		provide() {
			return {
				group: () => {
					return this.groupList
				},
				brand: () => {
					return this.brandList
				}
			}
		},
		methods: {
			init() {
				
				var listObj = {};
				var productInfo = {};
				var list = [];
				// console.log(this.defaultList,'this.defaultList')
				this.defaultList.map(item=>{
					item.id = item.Type;
					
					//转换时间
					if (item.StartTime && item.EndTime){
						item.EndTime = item.EndTime.split(' ')[0];
						item.StartTime = item.StartTime.split(' ')[0];
						item.EndTime = this.formatPrevDate(item.EndTime);
						item.time = [item.StartTime, item.EndTime]
					}
					// console.log(item.EndTime,item.time,'time')
					
					//转换数量
					item.value = item.ConditionType;
					if(item.ConditionType===6){
						item.min = item.Num;
						item.max = item.MaxNum;
					}else{
						item.total = item.Num;
					}
					
					//一个模块可以添加多个条件的，需要归类
					if ([8, 9, 10, 11, 12, 13, 14].indexOf(item.Type) > -1) {
					
						var currData = null,
							currIndex = 0;
						list.map((f, j) => {
							if (f.Type == item.Type) {
								currData = f;
								currIndex = j;
							}
						})
						if (currData) {
							currData.list.push(item)
							list.splice(currIndex, 1, currData);
						} else {
							list.push({
								Type: item.Type,
								id: item.Type,
								list: [item]
							});
						}
					}else{
						list.push(item);
					}
				});
				
				
				var ids = list.map(item => {
					return item.Type
				})

				this.menuList.map(item => {
					item.list.map(record => {
						record.checked = ids.indexOf(record.id) > -1;
						return record;
					})
					return item;
				})
				// console.log(list, 'lsit')
				this.list = list;
			},
			async getGroupList() {
				try {
					const res = await productGroupList();
					this.groupList = res.Result;
				} catch (e) {}
			},
			async getBrandList() {
				try {
					const res = await productBrandList();
					this.brandList = res.Result;
				} catch (e) {}
			},
			handleSearch(){
				
				if (this.keywords.trim() === ''){
					this.searchMenuList = [];
					this.isSearch = false;
					return;
				}
				
				var list = [];
				var key = this.keywords.trim();
				this.menuList.map(item=>{
					item.list.map(record=>{
						if (record.value.indexOf(key)>-1){
							list.push(record);
						}
					})
				})
				this.searchMenuList = list;
				this.isSearch = true;
			},
			addTag(record) {
				if (this.disabled) return;

				if (!record.checked) {
					record.checked = true;

					var obj = JSON.parse(JSON.stringify(record))
					delete obj.value;
					this.list.push(obj)
				} else {
					this.$confirm('是否确认删除该属性或行为？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.list = this.list.filter(item => {
							return item.id != record.id
						})
						record.checked = false;
					}).catch(() => {});
				}

			},
			handleDelete(index, id) {

				this.$confirm('是否确认删除该属性或行为?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var id = this.list[index].id;
					this.list.splice(index, 1);

					this.menuList.map(item => {
						item.list.map(record => {
							if (record.id == id) {
								record.checked = false;
							}
							return record
						})
						return item;
					})
				}).catch(() => {});
			},
			validForm() {
				return new Promise((resolve, reject) => {

					var items = this.$refs['item'];
					var errorIndex = -1;
					var saveList = [];
					var index = 0;
					if (!items||!items.length) {
						resolve([])
						return;
					}

					for (let i = 0; i < items.length; i++) {

						var dom = items[i];
						dom.validForm().then(res => {
							if (Array.isArray(res)){
								saveList.push(...res);
							}else{
								saveList.push(res);
							}
							
							index++;
							if (items.length == index) {
								if (errorIndex > -1) {
									this.scrollTo(errorIndex)
									reject()
								} else {
									resolve(saveList)
								}
							}

						}).catch(err => {
							
							if (errorIndex == -1 || (errorIndex != -1 && errorIndex > i)) {
								errorIndex = i;
							}
							
							index++;
							console.log(index, items.length)
							if (items.length == index) {
								if (errorIndex > -1) {
									this.scrollTo(errorIndex)
									reject()
								} else {
									resolve(saveList)
								}
							}
						})

					}

				})
			},
			valid() {

			},
			scrollTo(index) {
				console.log(index)
				var currentDom = document.querySelector('.item-' + index);
				var box = document.querySelector('.auto-add-tag-edit');
				box.scrollTop = currentDom.offsetTop;
			},
			menuScroll(index){
				this.tagIndex = index;
				var box = document.querySelector('.menu-box');
				
				if(index === 0){
					box.scrollTop = 100
				}
				if(index === 1){
					box.scrollTop = 245
				}
				if(index >= 2){
					box.scrollTop = 435
				}
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.add-tag {
		display: flex;
		overflow: hidden;
		flex: 1 1 auto;
		font-size: 14px;
	}

	.menu {
		flex: 0 0 auto;
		width: 495px;
		height: 100%;
		overflow: hidden;

		display: flex;
		flex-direction: column;

		.title {
			flex: 0 0 auto;
			font-size: 14px;
			color: #666;
			margin-bottom: 10px;
		}
		
		.tag-list{
			
			display: inline-flex;
			box-shadow: 0 0 10px 1px rgba(180, 180, 180, .1);
			border-radius: 5px;
			margin-left: 15px;
			margin-top: 5px;
			overflow: hidden;
			
			.tag {
				flex: 0 0 auto;
				padding: 0 15px;
				background-color: #fff;
				height: 40px;
				line-height: 40px;
				text-align: center;
				cursor: pointer;
				border: 1px solid #fff;
				color: #666;
				border-right: 1px solid #eee;
				
			}
			.tagCurrent{
				color: #fff;
				background-color: #409EFF;
			}
		}

		.menu-box {
			flex: 1 1 auto;
			border: 1px solid #ddd;
			background-color: #F8f8f9;

			overflow: auto;

			.menu-item-box {

				.menu-title {
					font-size: 16px;
					font-weight: bold;
					padding: 20px 10px 10px;
				}

				.menu-item-list {
					display: flex;
					flex-wrap: wrap;
					padding: 0 10px;
				}

				.menu-item {
					flex: 0 0 auto;
					padding: 0 15px;
					background-color: #fff;
					height: 40px;
					line-height: 40px;
					text-align: center;
					box-shadow: 0 0 10px 1px rgba(120, 120, 120, .1);
					cursor: pointer;
					border: 1px solid #fff;
					color: #666;
					margin-right: 10px;
					margin-bottom: 15px;
					border-radius: 5px;
				}

				.menuItemDisabled {
					background-color: #f9f9f9;
					cursor: not-allowed;
					border: 1px solid #f9f9f9;
					color: #666;
				}

				.menuItemCheck {
					color: #fff;
					background-color: #409EFF;
				}
			}
		}


	}

	.content {
		flex: 1 1 auto;
		margin-left: 30px;
		overflow: hidden;

		display: flex;
		flex-direction: column;

		.title {
			flex: 0 0 auto;
			font-size: 14px;
			color: #666;
			margin-bottom: 10px;
		}

		.list {
			flex: 1 1 auto;
			border: 1px solid #ddd;
			background-color: #f8f8f9;
			overflow: auto;
			position: relative;

			.item {
				position: relative;
				margin: 20px;
				background-color: #fff;

				.delete {
					position: absolute;
					right: 20px;
					top: 10px;
					font-size: 20px;
					color: #999;
					cursor: pointer;
				}
			}
		}
	}
</style>
