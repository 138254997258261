<template>
	<div class="customer-sex">
		<div class="title">累计支付金额：</div>
		<div class="tip">在设置时间范围内，客户累计支付成功的订单金额，支付成功即计入，不剔除退款</div>

		<div class="flex flex-box" v-for="(item, index) in ruleForm.list" :key="index">
			<div class="flex">
				<div style="margin-right: 10px;">在</div>
				<time-mod ref="timeMod1" :value="item" :disabled="disabled"></time-mod>
				<div style="margin-left: 10px;">期间，</div>
			</div>
			<div class="flex">
				<div class="txt">累计支付</div>
				<num-mod ref="numMod" :value="item" :disabled="disabled" txt="元" :precision="2"></num-mod>
			</div>
			<div class="delete" v-if="!disabled && ruleForm.list.length > 1" @click="handleDelete(index)"><i class="el-icon-close"></i></div>
		</div>
		
		<el-link type="primary" v-if="!disabled" class="add" @click="handleAddItem">添加条件</el-link>

	</div>
</template>

<script>
	import {
		MemberUserlevels
	} from '@/api/TurnTomySelf';
	
	import timeMod from '../components/time.vue';
	import numMod from '../components/num.vue';

	export default {
		components: {
			timeMod,
			numMod
		},
		props: {
			type: {
				type: Number,
				default: 0
			},
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			disabled: {
				type:Boolean,
				default:false
			}
		},
		data() {
		
			return {
				ruleForm: {
					list: [{
						time: '',
						total: undefined,
						value: 1,
					}],
					
				},
				
			};
		},
		mounted() {
			var obj = JSON.parse(JSON.stringify(this.itemData));
			if(obj.list && obj.list.length){
				this.ruleForm.list = obj.list;
			}
		},
		methods: {
			validForm() {
				return new Promise((resolve, reject) => {
					
					var p1 = new Promise((resolve, reject) => {
						var list = this.$refs['numMod'];
						var array = [];
						var index = 0;
						for (let i=0;i<list.length;i++){
							var dom = list[i];
							dom.validForm().then(res=>{
								index++;
								array.push(res);
								if (index === list.length){
									if (array.length == list.length){
										resolve(array)
									}else{
										reject();
									}
								}
							}).catch(err=>{
								index++;
								reject();
							})
						}
					})
					var p2 = new Promise((resolve, reject) => {
						var list = this.$refs['timeMod1'];
						var array = [];
						var index = 0;
						for (let i=0;i<list.length;i++){
							var dom = list[i];
							dom.validForm().then(res=>{
								index++;
								array.push(res);
								if (index === list.length){
									if (array.length == list.length){
										resolve(array)
									}else{
										reject();
									}
								}
							}).catch(err=>{
								index++;
								reject();
							})
						}
					})
			
					Promise.all([p1, p2]).then(res=>{
						if (res[1]){
							var list = [];
							res[1].map((item, i)=>{
								list.push({
									TagRuleConfigId: 0,
									Type: this.type,
									StartTime: item.time[0],
									EndTime: this.formatNextDate(item.time[1]),
									DynamicTimeType: item.DynamicTimeType,
									TimeType: item.TimeType,
									ConditionType: res[0][i].value,
									Num: res[0][i].value === 6 ? res[0][i].min : res[0][i].total,
									MaxNum: res[0][i].max
								});
								
							})
							
							resolve(list);
						}
					}).catch(err=>{
						reject();
						console.log(err, 'catch')
					})
				})
			},
			handleAddItem(){
				this.ruleForm.list.push({
					time: '',
					total: undefined,
					value: 1,
				})
			},
			handleDelete(index){
				this.ruleForm.list.splice(index, 1)
			}
		}
	}
</script>

<style lang="less" scoped>
	.customer-sex {
		padding: 15px 20px 1px;

		.title {
			font-size: 14px;
			margin-bottom: 7px;
		}

		.tip {
			font-size: 13px;
			color: #999;
			line-height: 18px;
		}
		
		.flex{
			display: flex;
			line-height: 36px;
			white-space: nowrap;
			flex-wrap: wrap;
		}
		.txt{
			height: 36px;
			line-height: 36px;
			white-space: nowrap;
		}
		.flex-box{
			flex-wrap: wrap;
			border-bottom: 1px dashed #ddd;
			margin-top: 15px;
		}
		.delete{
			height: 36px;
			line-height: 36px;
			font-size: 20px;
			margin-left: 30px;
			color: #999;
		}
		
		.add{
			margin: 15px 0;
		}
	}
</style>
