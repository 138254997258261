<template>
	<div class="customer-sex">
		<div class="title">未使用余额：</div>
		<div class="tip">客户超出N天没有使用余额支付订单</div>

		<div class="flex flex-box">
			<div class="flex">
				<div class="txt">客户当前余额</div>
				<num-mod ref="numMod" :value="ruleForm" :disabled="disabled" txt="元" :precision="2" :max="9999"></num-mod>
			</div>
			<div class="flex">
				，并且超出
				<el-form :model="ruleForm" :rules="rules" class="item" ref="ruleForm" label-width="0px">
					<el-form-item label="" prop="OverDayNum">
						<el-select v-model="ruleForm.OverDayNum" :disabled="disabled" style="width: 100px;margin-right: 5px;margin-left: 5px;">
							<el-option v-for="item in selectList" :key="item.id" :label="item.label" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
				没有使用余额支付订单
			</div>
		</div>

	</div>
</template>

<script>
	import {
		MemberUserlevels
	} from '@/api/TurnTomySelf';
	
	import numMod from '../components/num.vue';

	export default {
		components: {
			numMod
		},
		props: {
			type: {
				type: Number,
				default: 0
			},
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			disabled: {
				type:Boolean,
				default:false
			}
		},
		data() {
		
			return {
				ruleForm: {
					total: undefined,
					value: 1,
					OverDayNum: 7
				},
				rules: {
					OverDayNum: [{
						required: true,
						trigger: 'change',
						message: '请选择超出时间'
					}]
				},
				
				selectList: [
					{
						id: 3,
						label: '3天'
					},
					{
						id: 5,
						label: '5天'
					},
					{
						id: 7,
						label: '7天'
					},
					{
						id: 15,
						label: '15天'
					},
					{
						id: 30,
						label: '30天'
					},
					{
						id: 90,
						label: '90天'
					},
				]
			};
		},
		beforeMount() {
			var obj = JSON.parse(JSON.stringify(this.itemData));
			for (let item in obj){
				this.ruleForm[item] = obj[item]
			}
		},
		methods: {
			validForm() {
				return new Promise((resolve, reject) => {
					
					var p1 = new Promise((resolve, reject) => {
						var dom = this.$refs['numMod'];
	
						dom.validForm().then(res=>{
							resolve(res)
						}).catch(err=>{
							reject();
						})
					})
					var p2 = new Promise((resolve, reject) => {
						this.$refs['ruleForm'].validate((valid) => {
							if (valid) {
								resolve(this.ruleForm);
							} else {
								reject('')
							}
						});
					})
			
					Promise.all([p1, p2]).then(res=>{
						
						var obj = {
							TagRuleConfigId: 0,
							Type: this.type,
							ConditionType: res[0].value,
							Num: res[0].value === 6 ? res[0].min : res[0].total,
							MaxNum: res[0].max,
							OverDayNum: this.ruleForm.OverDayNum
						}
						
						resolve(obj);
						
					}).catch(err=>{
						reject();
						console.log(err, 'catch')
					})
				})
			},
			handleAddItem(){
				this.ruleForm.list.push({
					time: '',
					total: undefined,
					value: 1,
				})
			},
			handleDelete(index){
				this.ruleForm.list.splice(index, 1)
			}
		}
	}
</script>

<style lang="less" scoped>
	.customer-sex {
		padding: 15px 20px;

		.title {
			font-size: 14px;
			margin-bottom: 7px;
		}

		.tip {
			font-size: 13px;
			color: #999;
			line-height: 18px;
		}
		
		.flex{
			display: flex;
			line-height: 36px;
			white-space: nowrap;
			flex-wrap: wrap;
		}
		.txt{
			height: 36px;
			line-height: 36px;
			white-space: nowrap;
		}
		.flex-box{
			flex-wrap: wrap;
			margin-top: 15px;
		}
		.delete{
			height: 36px;
			line-height: 36px;
			font-size: 20px;
			margin-left: 30px;
			color: #999;
		}
		
		.add{
			margin: 15px 0;
		}
	}
</style>
