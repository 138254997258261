<template>
	<div class="customer-sex">
		<div class="title">分享商品：</div>
		<div class="tip">在设置时间范围内，客户分享商品的次数；分享行为包括但不限于小程序右上角点击[转发]，或者生成商品分享小程序码；可选择指定商品、指定商品分组、指定商品品牌</div>
		
		<div>
			<div class="buy-item">
				<div class="flex">
					<div style="margin-right: 10px;">以下任意一个商品，在</div>
					<time-mod ref="timeMod1" :value="ruleForm" :disabled="disabled"></time-mod>
					<div style="margin-left: 10px;">期间，分享次数</div>
				</div>
				<div class="flex">
					<num-mod ref="numMod" :value="ruleForm" :disabled="disabled" txt="次" :max="99" :precision="0"></num-mod>
				</div>
				
				<product-mod ref="productMod" :value="ruleForm" :disabled="disabled"></product-mod>
			</div>
		</div>
		
		<!-- <el-link type="primary" class="add" @click="handleAddItem">添加条件</el-link> -->
	</div>
</template>

<script>
	
	// import buyProductItem from './buyProductItem.vue';
	
	import timeMod from '../components/time.vue';
	import numMod from '../components/num.vue';
	import productMod from '../components/product.vue';
	
	export default {
		components: {
			// buyProductItem
			timeMod,
			numMod,
			productMod
		},
		props: {
			type: {
				type: Number,
				default: 0
			},
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			disabled: {
				type:Boolean,
				default:false
			}
		},
		data() {
			return {
				ruleForm: {}
			};
		},
		beforeMount() {
			var obj = JSON.parse(JSON.stringify(this.itemData));
			if (obj.Id){
				this.ruleForm = obj;
			}
		},
		methods: {
			validForm() {
				return new Promise((resolve, reject) => {
					
					var p1 = new Promise((resolve, reject) => {
						var dom = this.$refs['numMod'];
						dom.validForm().then(res=>{
							resolve(res)
						}).catch(err=>{
							reject();
						})
					})
					var p2 = new Promise((resolve, reject) => {
						var dom = this.$refs['timeMod1'];
						dom.validForm().then(res=>{
							resolve(res)
						}).catch(err=>{
							reject();
						})
					})
					var p3 = new Promise((resolve, reject) => {
						var dom = this.$refs['productMod'];
						dom.validForm().then(res=>{
							resolve(res)
						}).catch(err=>{
							reject();
						})
					})
			
					Promise.all([p1, p2, p3]).then(res=>{
						if (res[1]){
							var list = [];
							
							var productList = [];
							var relationId = null;
							if (res[2].ProductRangeType === 0){
								productList = res[2].list.map(item2=>{
									return {
										TagRuleConfigId: 0,
										TagRuleConfigDetailId: 0,
										RelationId: item2.ProductId,
										ProductName: item2.Name,
										ProductImgUrl: item2.ImgUrl,
										ProductImgUrlComplete: this.imgUrl + item2.ImgUrl
									}
									
								})
							}else{
								if (res[2].ProductRangeType === 1){
									relationId = res[2].groupId
								}else{
									relationId = res[2].brandId
								}
								productList = [{RelationId: relationId}]
							}
							
							list.push({
								TagRuleConfigId: 0,
								Type: this.type,
								StartTime: res[1].time[0],
								EndTime: this.formatNextDate(res[1].time[1]),
								DynamicTimeType: res[1].DynamicTimeType,
								TimeType: res[1].TimeType,
								ConditionType: res[0].value,
								Num: res[0].value === 6 ? res[0].min : res[0].total,
								MaxNum: res[0].max,
								TagRuleConfigDetailRelationIdList: productList,
								ProductRangeType: res[2].ProductRangeType
							});
							
							resolve(list);
						}
					}).catch(err=>{
						reject();
						console.log(err, 'catch')
					})
				})
			},
			validate(item){
				return new Promise((resolve, reject) => {
					item.validForm().then(res=>{
						resolve(res)
					}).catch(err=>{
						reject()
					})
				})
			},
			
			handleAddItem(){
				this.list.push({})
			}
		}
	}
</script>

<style lang="less" scoped>
	.customer-sex {
		padding: 15px 20px 15px;

		.title {
			font-size: 14px;
			margin-bottom: 7px;
		}

		.tip {
			font-size: 13px;
			line-height: 18px;
			color: #999;
		}
		.buy-item{
			margin-top: 15px;
			border-bottom: 1px dashed #ddd;
			
			&:last-child{
				border: none;
			}
		}
		
		.add{
			margin: 15px 0;
		}
		
		.flex{
			display: flex;
			line-height: 36px;
			white-space: nowrap;
			flex-wrap: wrap;
		}
	}
</style>
