<template>
	<div class="customer-sex">
		<div class="title">笔单价：</div>
		<div class="tip">客户累计支付金额/累计支付笔数，支付成功即计入，不剔除退款</div>

		<div class="flex">
			<div class="flex">
				<num-mod ref="numMod" :disabled="disabled" txt="元" :precision="2"></num-mod>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		MemberUserlevels
	} from '@/api/TurnTomySelf';
	
	import numMod from '../components/num.vue';

	export default {
		components: {
			numMod
		},
		props: {
			type: {
				type: Number,
				default: 0
			},
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			disabled: {
				type:Boolean,
				default:false
			}
		},
		data() {
			
			return {
			};
		},
		mounted() {
			if(this.itemData.Id){
				var obj = JSON.parse(JSON.stringify(this.itemData));
				var mod = this.$refs['numMod'];
				for (let item in mod.ruleForm){
					mod.ruleForm[item] = obj[item];
				}
			}
		},
		methods: {
			validForm() {
				return new Promise((resolve, reject) => {
					this.$refs['numMod'].validForm().then(res=>{
						var obj = {
							TagRuleConfigId: 0,
							Type: this.type,
							ConditionType: res.value,
							Num: res.value === 6 ? res.min : res.total,
							MaxNum: res.max
						}
						resolve(obj);
					}).catch(err=>{
						reject();
					})
				})

			},
		}
	}
</script>

<style lang="less" scoped>
	.customer-sex {
		padding: 15px 20px 1px;

		.title {
			font-size: 14px;
			margin-bottom: 7px;
		}

		.tip {
			font-size: 13px;
			color: #999;
			margin-bottom: 15px;
			line-height: 18px;
		}
		
		.flex{
			display: flex;
			line-height: 36px;
			white-space: nowrap;
			flex-wrap: wrap;
		}
	}
</style>
