<template>
	<div class="customer-sex">
		<div class="title">客户性别：</div>
		<div class="tip">客户基础资料中保存的性别；若客户性别选择[保密]或未填写性别，系统判断为：未知</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" size="mini">
			<el-form-item label="" prop="check" inline-message>
				<el-checkbox-group v-model="ruleForm.check" :disabled="disabled">
					<el-checkbox v-for="item in checkList" :label="item.id" :key="item.id">{{item.value}}
					</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
		</el-form>

	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			type: {
				type: Number,
				default: 0
			},
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			disabled: {
				type:Boolean,
				default:false
			}
		},
		data() {

			var validCheck = (rule, value, callback) => {
				if (!value.length) {
					callback(new Error('请选择客户性别'));
				} else {
					callback();
				}
			};

			return {
				ruleForm: {
					check: []
				},
				rules: {
					check: [{
						required: true,
						trigger: 'change',
						validator: validCheck
					}]
				},

				checkList: [{
					id: 1,
					value: '男'
				}, {
					id: 2,
					value: '女'
				}, {
					id: 0,
					value: '未知'
				}]
			};
		},
		beforeMount() {
			var obj = JSON.parse(JSON.stringify(this.itemData));
			if (obj.TagRuleConfigDetailRelationIdList && obj.TagRuleConfigDetailRelationIdList.length){
				this.ruleForm.check = obj.TagRuleConfigDetailRelationIdList.map(item=>{
					return item.RelationId
				})
			}
		},
		methods: {
			validForm(){
				return new Promise((resolve, reject)=>{
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							var obj = {
								TagRuleConfigId: 0,
								Type: this.type,
								TagRuleConfigDetailRelationIdList: this.ruleForm.check.map(item=>{
									return {
										RelationId: item
									}
								})
							}
							resolve(obj);
						} else {
							reject()
						}
					});
				})
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.customer-sex {
		padding: 15px 20px 1px;

		.title {
			font-size: 14px;
			margin-bottom: 7px;
		}

		.tip {
			font-size: 13px;
			color: #999;
			margin-bottom: 5px;
		}
	}
</style>
