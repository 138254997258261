<template>
	<el-form :model="ruleForm" :rules="rules" class="item" ref="ruleForm" label-width="0px">
		<el-form-item label="">
			<el-select v-model="ruleForm.value" :disabled="disabled" placeholder="请选择" style="width: 200px;margin-right: 5px;margin-left: 5px;" @change="selectChange">
				<el-option v-for="item in options" :key="item.value" :label="item.labelText" :value="item.value">
					{{item.label}}<span style="color: #999;font-size: 12px;" v-if="item.tip">{{item.tip}}</span>
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="" prop="total" v-if="ruleForm.value !== 6">
			<div class="item">
				<el-input :disabled="disabled" v-model="ruleForm.total" style="width: 140px;"></el-input>
				<div class="txt" style="margin-left: 5px;">{{txt}}</div>
			</div>
		</el-form-item>
		<el-form-item label="" prop="min" v-if="ruleForm.value === 6">
			<div class="item">
				<el-input :disabled="disabled" v-model="ruleForm.min" style="width: 140px;" placeholder="最小值"></el-input>
				<div class="txt" style="margin-left: 5px;">{{txt}}(含)</div>
				<span style="padding: 0 10px;">~</span>
				<el-input :disabled="disabled" v-model="ruleForm.max" style="width: 140px;" placeholder="最大值"></el-input>
				<div class="txt" style="margin-left: 5px;">{{txt}}(含)</div>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
	
	export default {
		components: {},
		props: {
			value: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			disabled: {
				type:Boolean,
				default:false
			},
			txt: {
				type: String,
				default: ''
			},
			//数值精度
			precision: {
				type: Number,
				default: 0
			},
			max: {
				type: Number,
				default: 99999
			}
		},
		data() {
			
			var p = this.precision > 0 ? '金额':'数量';
			
			var validTotal = (rule, value, callback) => {
				var valStr = (value + '').trim();
				
				var min = 0;
				if (this.ruleForm.value == 2 || this.ruleForm.value == 4 || this.ruleForm.value == 6){
					if (this.precision > 0){
						min = 0.01;
					}else{
						min = 1;
					}
				}
				
				if (this.precision === 0){
					if (value === undefined || valStr === '' || isNaN(value)) {
						callback(new Error(p + '请输入在'+min+'~'+this.max+'之间，整数'));
					} else if (valStr.split(".")[1] && valStr.split(".")[1].length>0){
						callback(new Error(p + '请输入在'+min+'~'+this.max+'之间，整数'));
					} else if (value<min || value > this.max){
						callback(new Error(p + '请输入在'+min+'~'+this.max+'之间，整数'));
					}else {
						callback();
					}
				}else{
					if (value === undefined || valStr === '' || isNaN(value)) {
						callback(new Error(p + '请输入在'+min+'~'+this.max+'之间，最多'+this.precision+'位小数'));
					} else if (valStr.split(".")[1] && valStr.split(".")[1].length>this.precision){
						callback(new Error(p + '请输入在'+min+'~'+this.max+'之间，最多'+this.precision+'位小数'));
					} else if (value<min || value > this.max){
						callback(new Error(p + '请输入在'+min+'~'+this.max+'之间，最多'+this.precision+'位小数'));
					}else {
						callback();
					}
				}
				
			};
			
			var validMin = (rule, value, callback) => {
				var min = (this.ruleForm.min+'').trim();
				var max = (this.ruleForm.max+'').trim();
				var minNum = +min;
				var maxNum = +max;
				
				var minVal = 0;
				if (this.ruleForm.value == 2 || this.ruleForm.value == 4 || this.ruleForm.value == 6){
					if (this.precision > 0){
						minVal = 0.01;
					}else{
						minVal = 1;
					}
				}
				
				if (min==='' || min===undefined || max==='' || max===undefined || isNaN(min) || isNaN(max)){
					callback(new Error('请输入正确的区间'));
				}else if ((min.split(".")[1] && min.split(".")[1].length>this.precision) || (max.split(".")[1] && max.split(".")[1].length>this.precision)){
					if (this.precision>0){
						callback(new Error('请输入正确的区间，最多'+this.precision+'位小数'));
					}else{
						callback(new Error('请输入正确的区间，整数'));
					}
				}else if ((minNum<minVal || minNum > this.max) || (maxNum<minVal || maxNum > this.max)){
					callback(new Error('请输入正确的区间'));
				}else if (minNum>maxNum){
					callback(new Error('请输入正确的区间'));
				}else{
					callback();
				}
			};
			
			return {
				ruleForm: {
					total: undefined,
					value: 1,
					min: undefined,
					max: undefined
				},
				rules: {
					total: [{
						required: true,
						trigger: 'blur',
						validator: validTotal
					}],
					min: [{
						required: true,
						trigger: 'blur',
						validator: validMin
					}]
				},
				
				options: [{
					value: 1,
					label: '大于',
					labelText: '大于',
					tip: '',
				}, {
					value: 2,
					label: '大于等于',
					labelText: '大于等于',
					tip: '',
				}, {
					value: 3,
					label: '小于',
					labelText: '小于',
					tip: '',
				}, {
					value: 4,
					label: '小于等于',
					labelText: '小于等于',
					tip: '',
				}, {
					value: 5,
					label: '等于',
					labelText: '等于',
					tip: '',
				}, {
					value: 6,
					label: '区间',
					labelText: '区间 （最小值-最大值）',
					tip: '（最小值-最大值）',
				}],
			};
		},
		watch: {
			value() {
				
				for (let item in this.ruleForm){
					if (this.value[item] !== undefined){
						this.ruleForm[item] = this.value[item];
					}
				}
			}
		},
		mounted() {
			for (let item in this.ruleForm){
				if (this.value[item] !== undefined){
					this.ruleForm[item] = this.value[item];
				}
			}
		},
		methods: {
			validForm() {
				return new Promise((resolve, reject) => {
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							resolve(this.ruleForm);
						} else {
							reject('')
						}
					});
				})
			},
			selectChange(){
				this.$refs['ruleForm'].clearValidate();
			}
		}
	}
</script>

<style lang="less" scoped>
	.item{
		display: flex;
		flex-wrap: wrap;
	}
</style>
