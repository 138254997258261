<template>
	<div class="customer-sex">
		<div class="title">加购物车商品：</div>
		<div class="tip">客户当前购物车里存在下列商品中的任意一个；可选择指定商品、指定商品分组、指定商品品牌</div>
		
		<div>
			<div class="flex">
				<div style="margin-right: 10px;">以下任一商品，在购物车中停留时长超出</div>
				<el-form :model="ruleForm" :rules="rules" class="item" ref="ruleForm" label-width="0px">
					<el-form-item label="" prop="OverDayNum">
						<el-select v-model="ruleForm.OverDayNum" :disabled="disabled" style="width: 140px;margin-right: 5px;margin-left: 5px;">
							<el-option v-for="item in selectList" :key="item.id" :label="item.label" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			
			<product-mod ref="productMod" :value="ruleForm" :disabled="disabled"></product-mod>
		</div>
		
	</div>
</template>

<script>
	
	import productMod from '../components/product.vue';
	
	export default {
		components: {
			productMod
		},
		props: {
			type: {
				type: Number,
				default: 0
			},
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			disabled: {
				type:Boolean,
				default:false
			}
		},
		data() {
			return {
				ruleForm: {
					OverDayNum: 7
				},
				rules: {
					OverDayNum: [{
						required: true,
						trigger: 'change',
						message: '请选择超出时间'
					}]
				},
				
				selectList: [
					{
						id: 3,
						label: '3天'
					},
					{
						id: 5,
						label: '5天'
					},
					{
						id: 7,
						label: '7天'
					},
					{
						id: 15,
						label: '15天'
					},
					{
						id: 30,
						label: '30天'
					},
					{
						id: 90,
						label: '90天'
					},
				]
			};
		},
		beforeMount() {
			var obj = JSON.parse(JSON.stringify(this.itemData));
			if (obj.Id){
				this.ruleForm = obj;
			}
		},
		methods: {
			validForm() {
				return new Promise((resolve, reject) => {
					
					var p1 = new Promise((resolve, reject) => {
						this.$refs['ruleForm'].validate((valid) => {
							if (valid) {
								resolve(this.ruleForm);
							} else {
								reject('')
							}
						});
					})
					var p2 = new Promise((resolve, reject) => {
						var dom = this.$refs['productMod'];
						
						dom.validForm().then(res=>{
							resolve(res)
						}).catch(err=>{
							reject();
						})
					})
			
					Promise.all([p1, p2]).then(res=>{
						if (res[1]){
							var list = [];
								
							var productList = [];
							var relationId = null;
							
							if (res[1].ProductRangeType === 0){
								productList = res[1].list.map(item2=>{
									return {
										TagRuleConfigId: 0,
										TagRuleConfigDetailId: 0,
										RelationId: item2.ProductId,
										ProductName: item2.Name,
										ProductImgUrl: item2.ImgUrl,
										ProductImgUrlComplete: this.imgUrl + item2.ImgUrl
									}
									
								})
							}else{
								if (res[1].ProductRangeType === 1){
									relationId = res[1].groupId
								}else{
									relationId = res[1].brandId
								}
								productList = [{RelationId: relationId}]
							}
							
							
							var obj = {
								TagRuleConfigId: 0,
								Type: this.type,
								TagRuleConfigDetailRelationIdList: productList,
								OverDayNum: this.ruleForm.OverDayNum,
								ProductRangeType: res[1].ProductRangeType
							}
							
							resolve(obj);
						}
					}).catch(err=>{
						reject();
						console.log(err, 'catch')
					})
				})
			},
			validate(item){
				return new Promise((resolve, reject) => {
					item.validForm().then(res=>{
						resolve(res)
					}).catch(err=>{
						reject()
					})
				})
			},
			
			handleAddItem(){
				this.list.push({})
			}
		}
	}
</script>

<style lang="less" scoped>
	.customer-sex {
		padding: 15px 20px 15px;

		.title {
			font-size: 14px;
			margin-bottom: 7px;
		}

		.tip {
			font-size: 13px;
			color: #999;
			line-height: 18px;
		}
		.buy-item{
			margin-top: 15px;
			border-bottom: 1px dashed #ddd;
			
			&:last-child{
				border: none;
			}
		}
		
		.add{
			margin: 15px 0;
		}
		
		.flex{
			display: flex;
			line-height: 36px;
			white-space: nowrap;
			margin-top: 20px;
			flex-wrap: wrap;
		}
	}
</style>
