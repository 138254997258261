<template>
	<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px">
		<div class="flex">
			选择
			<el-form-item label="">
				<el-select v-model="ruleForm.ProductRangeType" :disabled="disabled" style="width: 140px;margin-right: 5px;margin-left: 5px;" @change="selectChange">
					<el-option v-for="item in typeList" :key="item.id" :label="item.label" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="" prop="list" style="width: 100px;line-height: 30px;" v-if="!disabled && ruleForm.ProductRangeType===0">
				<el-link type="primary" style="line-height: 20px;" @click="visible=true">选择商品</el-link>
			</el-form-item>
			<el-form-item label="" prop="groupId" v-if="ruleForm.ProductRangeType===1">
				<el-select v-model="ruleForm.groupId" :disabled="disabled" filterable style="width: 200px;margin-right: 5px;margin-left: 5px;"  >
					<el-option v-for="item in groupList" :key="item.Id" :label="item.GroupName" :value="item.Id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="" prop="brandId" v-if="ruleForm.ProductRangeType===2">
				<el-select v-model="ruleForm.brandId" :disabled="disabled" filterable style="width: 200px;margin-right: 5px;margin-left: 5px;">
					<el-option v-for="item in brandList" :key="item.Id" :label="item.BrandName" :value="item.Id">
					</el-option>
				</el-select>
			</el-form-item>
		</div>

		<product-table :disabled="disabled" ref="giftTable" v-if="ruleForm.list.length && ruleForm.ProductRangeType===0" style="margin-top:10px"
		 :gifts="ruleForm.list" @refresh="handleSelect"></product-table>
	
		<el-dialog :visible.sync="visible" width="1100px" title="选择商品" class = "dialogpad">
			<selectProduct v-if="visible" @select="handleSelect" :selectedData="ruleForm.list"></selectProduct>
		</el-dialog>
	</el-form>
</template>

<script>
	
	import selectProduct from './selectProduct.vue';
	import productTable from '../productTable/productTable.vue';
	
	export default {
		components: {
			selectProduct,
			productTable
		},
		props: {
			type: {
				type: Number,
				default: 0
			},
			disabled: {
				type:Boolean,
				default:false
			},
			value: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
		},
		data() {
			
			var validList = (rule, value, callback) => {
				if (!value.length){
					callback(new Error('请选择商品'));
				}else {
					callback();
				}
			};
			
			return {
				ruleForm: {
					ProductRangeType: 0,
					groupId: '',
					brandId: '',
					list: []
				},
				rules: {
					list: [{
						required: true,
						trigger: 'change',
						validator: validList
					}],
					groupId:  [{
						required: true,
						trigger: 'change',
						message: '请选择分组'
					}],
					brandId:  [{
						required: true,
						trigger: 'change',
						message: '请选择品牌'
					}],
				},
				
				typeList: [{
					id: 0,
					label: '指定商品'
				},{
					id: 1,
					label: '指定商品分组'
				},{
					id: 2,
					label: '指定商品品牌'
				}],
				
				visible: false
			};
		},
		inject: ['brand', 'group'],
		computed: {
			groupList() {
				return this.group();
			},
			brandList(){
				return this.brand();
			},
		},
		watch: {
			value(){
				this.init();
			},
		},
		mounted() {
			this.init();
		},
		methods: {
			init(){
				if (!this.value.Id) return;
				
				this.ruleForm.ProductRangeType = this.value.ProductRangeType;
				if (this.ruleForm.ProductRangeType === 1){
					this.ruleForm.groupId = this.value.TagRuleConfigDetailRelationIdList[0].RelationId
				}
				if (this.ruleForm.ProductRangeType === 2){
					this.ruleForm.brandId = this.value.TagRuleConfigDetailRelationIdList[0].RelationId
				}
				if (this.ruleForm.ProductRangeType === 0){
					this.ruleForm.list = this.value.TagRuleConfigDetailRelationIdList.map(item=>{
						return {
							ProductId: item.RelationId,
							Name: item.ProductName,
							ImgUrl: item.ProductImgUrl
						}
					})
				}
				
			},
			validForm() {
				// console.log('product')
				return new Promise((resolve, reject) => {
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							resolve(this.ruleForm);
						} else {
							reject('')
						}
					});
				})
			},
			selectChange(){
				this.$refs['ruleForm'].clearValidate();
				if (this.ruleForm.ProductRangeType == 1 && !this.ruleForm.groupId && this.groupList.length){
					this.ruleForm.groupId = this.groupList[0].Id
				}
				if (this.ruleForm.ProductRangeType == 2 && !this.ruleForm.brandId && this.brandList.length){
					this.ruleForm.brandId = this.brandList[0].Id
				}
			},
			handleSelect(list){
				this.ruleForm.list = list;
				this.visible = false;
			}
		}
	}
</script>

<style lang="less" scoped>
	.flex{
		display: flex;
		line-height: 36px;
		flex-wrap: wrap;
	}
</style>
<style>
.dialogpad .el-dialog__body{
	padding:10px 20px 30px
}
</style>
